/* General styling for the flow */
.Flow {
  flex-grow: 1;
  font-size: 14px;
  height: 100%; 
  width: 100%;
  background-color: #001010;  
  color: #FFF;  
}

/* General Node Styling */
.react-flow__node {
  border-radius: 5px;
  width: 250px;
  text-align: center;
  font-weight: 600;
  transition: transform 0.3s;
}
.react-flow__node:hover {
  transform: scale(1.05);
}

/* Styling for custom nodes */
.react-flow__node-customNode {
  border: 1px solid #869c95;
  padding: 15px;
  color: #FFF;
  background: linear-gradient(145deg, #012121, #0e1b1b);
  border-radius: 2rem 0rem 2rem 0rem;
}

/* Styling for start nodes */
.react-flow__node-startNode {
  border: 2px solid var(--brand);
  background: linear-gradient(145deg, #012121, #0e1b1b);
  color: #FFF;
  padding: 20px;
  width: 400px;
  border-radius: 2rem 0rem 2rem 0rem;
}
.react-flow__node-startNode .node-title {
  font-size: 1.5rem;
  text-wrap: pretty;
}

.react-flow__handle.connectionindicator {
  cursor: unset;
}

/* Styling for Flow Edge Line */
react-flow__edge react-flow__edge-default nopan selected {

}

/* Button styles */
button {
  font-family: Jost, sans-serif;
  background-color: #0e2e2e;
  color: #ffffffd1;
  padding: 0.5em 1.0em;  /* Changed to relative units */
  font-size: .75rem;  /* Explicit font-size */
  line-height: 1.5em;  /* Adjust as needed */
  border-radius: 5px;
  border: 1px solid #7e7e7e5e;
  cursor: pointer;
  font-weight: 500;  /* Semi-bold */
  transform: scale(1);  /* By default, no transform */
}

button:hover {
  background-color: var(--brand); 
  color:#000;
}

/* Edit Button */
button.edit-button {
  margin-left: 0.5rem;
}

/* Lines and connectors */
.react-flow__edges g path {
  stroke: var(--brand);
  stroke-width: 2px;
  transition: stroke-dasharray 0.3s ease;
}

/* Node Handle */
.react-flow__handle-bottom {
  bottom: -5px;
  transform: translate(-50%, 0);
}
.react-flow__handle {
  min-width: 5px;
  min-height: 5px;
  width: 10px;
  height: 10px;
  background: #1a192b;
  border: 2px solid var(--brand);
}

/* Color for node header */
.node-header-ori, .node-header-problem, .node-header-vision, .node-header-idea, .node-header-evaluation, .node-header-critique {
  padding: 2px;
  text-align: center;
  font-weight: 600;
  font-size: .65rem;
  letter-spacing: 1px;
}
.node-header-ori {
  color: var(--brand);
}
.node-header-problem {
  color: #f9875e;   /* Orange */
}
.node-header-vision {
  color: #8ddff7;   /* Blue */
}
.node-header-idea {
  color: #d4a352;   /* Yellow */
}
.node-header-evaluation {
  color: #b696dd;   /* Purple */
}
.node-header-critique {
  color: #f38181;   /* Red */
}

.node-title {
  font-weight: 700;
  margin-bottom: 5px;
  color: var(--accent-light);
}

.node-description {
  font-weight: 400;
  font-size: .8rem;
  color: #ebf4f1ab;
}

.loader-container p {
  font-size: 12px;
  color: #555;
}

.loader-container {
  position: absolute;
  top: -130px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  color: #555; /* Matched with flow styles */
}