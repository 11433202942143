@font-face {
  font-family: 'Jost';
  src: local('Jost-VariableFont_wght'),
       url('../fonts/Jost-VariableFont_wght.woff2') format('woff2');
}

.App {
font-family: Jost, sans-serif;
height: 100%;
min-height: 900px;
display: flex;
flex-direction: column;
background-color: #001010;
}

.App-header {
  display: flex;
  align-items: center;
  height: 40px;
  border-bottom: 1px solid #00000094;
  padding: 1.8rem;
  background-color: #001818;
  font-family: Jost, sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  text-transform: capitalize;
}

body {
  font-family: Jost, sans-serif;
}

/* Top Card */
#root .top-container {
  padding: 3rem;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 3;
}
#root .top-card {
  background-color: var(--shade);
  min-height: 230px;
  border-radius: 1rem;
}
#root .top-card__content {
  padding: 2rem 3rem;
}

.top-card__body {
    padding: 1rem 0 2rem 0;
}

/* ORI Card - Default */
#root .ori-card {
  background-image: none;
  border: 1px solid #52d4a912;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  transition: all .2s ease-in-out;
}
#root .ori-card:hover {
 /* background-color: #5a5a5a42; */
  border: 1px solid #52d4a980;
  transform: scale(1.03);
}

#root .ori-card__title, #root .ori-card__button {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}
#root .ori-card__title { /* Card Header Column */
  width: 85%;
}
#root .ori-card__button { /* Card Icon Column */
  /* width: 15%; */
}

/* Card - Active */
#root .ori-card__onclick {
  background-color: var(--brand);
  color: var(--base);
  cursor: pointer;
  width: 280px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
}

/* Button */
#root .button-main {
  background-color: var(--brand);
}
#root .button-main.Mui-disabled {
  opacity: .4;
  -webkit-filter: grayscale(50%);
  filter: grayscale(50%);
  background-color: var(--shade);
}

/* ORIs - Title */
#root .ori-card__title span {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.2rem;
}

/* ORIs - Dropdown Icon */
#root .ori-card__icon, #root .ori-card__button {
  color: #52d4a987;
  padding: 0;
}
#root .ori-card__icon-onclick {
  color: var(--base);
}
#root .ori-card__icon[aria-expanded="true"] {   /* Rotate on Click */
  transform: rotate(-180deg);
}
#root .ori-card__icon:hover {
  background-color: unset;
}

/* Footer */
.footer-container {
  display: flex;
  justify-content: center;
  padding: 2rem 0 3rem 0;
}
#root .footer-button {
  font-family: Jost, sans-serif;
  font-size: 1.2rem;
  padding: 0.5rem 2rem;
}

/* Overwrite Base Roboto Font */
.MuiContainer-root h4,
.MuiContainer-root span,
.MuiContainer-root button,
.MuiCardHeader-title,
.ori-card__title span,
.ori-card__description
{
font-family: Jost, sans-serif !important;
}

:root {
  font-family: Jost, sans-serif;
  --base: #003322;
  --accent: #8df7d4;
  --brand: #52D4A9;
  --shade: #001F1F;
  --white: #fff;
  --black: #000;
  --base-hover: #103a2c;
  --accent-light: #daf8ef;
}